<template>
  <header>
    <nav>
      <ul>
        <li v-for="menuItem in menuItems" :key="menuItem.ID">
          <a :href="generateAbsoluteUrl(menuItem.url)">{{ menuItem.title }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [],
      baseUrl: process.env.VUE_APP_BASE_URL, // Use the environment variable
    };
  },
  mounted() {
    console.log('API URL:', process.env.VUE_APP_API_URL);
    console.log('Base URL:', process.env.VUE_APP_BASE_URL);
    this.fetchMenuItems();
  },
  methods: {
    fetchMenuItems() {
      // Make the API request to fetch the primary menu items
      fetch(process.env.VUE_APP_PRIMARY_MENU_ENDPOINT_BASE + 'primary-menu-endpoint/v1/primary-menu')
        .then((response) => response.json())
        .then((data) => {
          this.menuItems = data;
        })
        .catch((error) => {
          console.error('Error fetching menu items:', error);
        });
    },
    generateAbsoluteUrl(relativeUrl) {
      if (relativeUrl && relativeUrl.startsWith(process.env.VUE_APP_WORDPRESS_BASE_URL)) {
        return this.baseUrl + relativeUrl.substr(process.env.VUE_APP_WORDPRESS_BASE_URL.length);
      } else {
        return relativeUrl;
      }
    },
  },
};
</script>
