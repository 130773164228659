<template>
    <footer>
      <p>&copy; 2023 Your Website Name. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'SiteFooter',
  };
  </script>
  
  <!-- <style scoped>
  /* Add your custom styles for the footer */
  footer {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
  }
  </style> -->
  