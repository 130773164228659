<template>
  <div>
    <h2>Contact</h2>
    <!-- Your contact form or information here -->
  </div>
</template>

<script>
export default {
  name: 'ContactView',
};
</script>
